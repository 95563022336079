<template>
  <div id="app">
    <Loading />
    <a-config-provider :locale="zh_CN">
      <router-view v-slot="{ Component }">
        <!--   <keep-alive :exclude="keepRouteList"> -->
        <component :is="Component" />
        <!--  </keep-alive>-->
      </router-view>
    </a-config-provider>
  </div>
</template>

<script setup>
import { ref } from '@vue/reactivity';
import { computed, watch } from '@vue/runtime-core';
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
import 'dayjs/locale/zh-cn'
import Loading from './views/components/loading/index.vue'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
moment.locale('zh-cn');

const route = useRoute()
const store = useStore()
// let keepComponent = computed(() => store.state.activeKeep)
// let keepRouteList = computed(() => store.state.keepRouteList)

</script>

<style >
#app {
  width: 100%;
  height: 100vh;
  /* overflow-x: hidden; */
  overflow-y: auto;
  scrollbar-color: rgba(133, 158, 203, 0.4) rgba(230, 230, 255, 0.2);
  /* 第一个方块颜色，第二个轨道颜色(用于更改火狐浏览器样式) */
  scrollbar-width: thin;
  /* font-family: "Noto Sans SC"; */
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
div,
dd {
  margin: 0;
  padding: 0;
}
ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  border: none;
  vertical-align: top;
}
/* 整个滚动条 */
::-webkit-scrollbar {
  width: 8rem;
  height: 15rem;
  /* background-color: #f5f5f5; */
}
/* 滚动条上的按钮 (上下箭头). */
::-webkit-scrollbar-button {
  /* background-color: #2196f3;
  border-radius: 50px;
  height: 20px;
  width: 20px; */
  display: none;
}
/* 滚动条上的滚动滑块. */
::-webkit-scrollbar-thumb {
  background-color: rgba(133, 158, 203, 0.4);
  border-radius: 50px;
}
/*  滚动条轨道. */
::-webkit-scrollbar-track {
  width: 8rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 142, 255, 1);
  box-shadow: inset 0 0 6px rgba(0, 142, 255, 1, 1);
  background-color: none;
  border-radius: 20px;
}
/* 滚动条没有滑块的轨道部分 */
::-webkit-scrollbar-track-piece {
  border-radius: 20rem;
}
/* 当同时有垂直滚动条和水平滚动条时交汇的部分. */
::-webkit-scrollbar-corner {
  background: transparent;
}
/* 某些元素的corner部分的部分样式(例:textarea的可拖动按钮). */
::-webkit-resizer {
}
</style>
