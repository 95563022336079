<template>
  <div>
    <a-pagination show-size-changer
                  show-quick-jumper
                  :default-current="defaultCurrent"
                  v-model:page-size="pageSize"
                  v-model:current="current"
                  :hide-on-single-page="hideOnSinglePage"
                  :page-size-options="pageSizeOptions"
                  :total="total"
                  :show-total="total => `共 ${total} 条`"
                  @change="currentChange"
                  @showSizeChange="onShowSizeChange" />
  </div>
</template>

<script setup>
import { reactive, ref, toRef, toRefs } from '@vue/reactivity'
import { onMounted, watch } from 'vue'
let props = defineProps({
  total: {
    type: Number,
    default: 0
  },
  defaultCurrent: {
    type: Number,
    default: 1
  },
  hideOnSinglePage: {
    type: Boolean,
    default: false
  },
  defaultInit: {
    type: Boolean,
    default: true
  },
  nowPage: {
    type: Number,
    default: 1
  }
})
let { total, defaultCurrent, hideOnSinglePage, defaultInit, nowPage } = toRefs(props)

// 接收自定义事件
const emit = defineEmits(['initData'])
let pageSizeOptions = ref(['9', '21', '42', '63']);
let current = ref(1)
let pageSize = ref(9)

const onShowSizeChange = (page, size) => {
  current.value = page
  pageSize.value = size
  refresh()
}
watch(() => props.nowPage, () => {
  current.value = nowPage.value
})

// 页码变化触发回调
const currentChange = (page, size) => {
  onShowSizeChange(page, size)
}
// 刷新
const refresh = () => {
  emit('initData', current.value, pageSize.value)
}
const initData = () => {
  emit('initData', 1, pageSize.value)
}
onMounted(() => {
  if (defaultInit.value)
    initData()
})

defineExpose({
  refresh, initData
})


// export default {
//   props: {
//     total: {
//       type: Number,
//       default: 0
//     },
//     defaultCurrent: {
//       type: Number,
//       default: 1
//     }
//   },
//   setup (props, context) {
//     let globalData = reactive({
//       current: 1,
//       pageSize: 10,
//     })

//     // 当分页的size改变触发的回调
//     const onShowSizeChange = (current, pageSize) => {
//       globalData.current = current
//       globalData.pageSize = pageSize
//       refresh()
//     }
//     // 页码变化触发回调
//     const currentChange = (page, pageSize) => {
//       onShowSizeChange(page, pageSize)
//     }
//     // 刷新
//     const refresh = () => {
//       context.emit('initData', globalData.current, globalData.pageSize)
//     }
//     const initData = () => {
//       context.emit('initData', 1, globalData.pageSize)
//     }
//     onMounted(initData)
//     return {
//       ...toRefs(globalData),
//       onShowSizeChange,
//       currentChange,
//       initData,
//       refresh
//     }
//   }
// }
</script>

<style lang="scss" scoped>
:deep .ant-pagination {
  margin-top: 10px;
  li {
    border-radius: 8rem;
    font-size: 20rem;
    vertical-align: middle;
    line-height: 30rem;
    height: auto;
    border: 0;
  }
  .ant-pagination-total-text {
    font-size: 20rem;
    // line-height: 40rem;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 8rem;
    color: #666;
    font-size: 20rem;
    line-height: 30rem;
    border: 0;
    height: 43rem;
    padding: 0 13rem;
    padding-right: 34rem;
    // width: 40rem;
    // height: 40rem;
    // line-height: 40rem;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 39rem;
    padding-right: 18rem;
  }
  .ant-select-arrow {
    color: #666;
  }
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-radius: 8rem;
    line-height: 30rem;
    padding: 5rem 14rem;
    height: auto;
    border: 0;

    // line-height: 40rem;
    // margin: 0;
    // margin-top: -4rem;
  }

  .ant-select-arrow {
    right: 12rem;
    width: 24rem;
    font-size: 20rem;
    height: 20rem;
    margin-top: -10rem;
  }

  .ant-pagination-item a {
    color: #999;
    padding: 0;
  }
  .ant-pagination-item-active a {
    color: #2196f3;
  }
  .ant-pagination-item:hover a {
    color: #2196f3;
  }
  .ant-pagination-item {
    // min-width: 40rem;
    // line-height: 40rem;
    // height: 40rem;

    padding: 3rem 14rem 6rem;
    height: auto;
  }
  //
  .ant-pagination-options-quick-jumper input {
    border-radius: 8rem;
    line-height: 30rem;
    padding: 5rem 14rem;
    height: auto;
  }

  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis {
    color: rgb(255 255 255 / 45%);
  }
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: #e2eefe;
  }
}
</style>