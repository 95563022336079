<template>
  <div v-drag>
    <a-modal :visible="visible"
             :title="title"
             :ok-text="okText"
             :maskClosable="false"
             :cancel-text="cancelText"
             :footer='footer'
             :width="width"
             @cancel="cancel"
             @ok="submit">
      <slot name="content" />
    </a-modal>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, toRef } from "vue"
const emit = defineEmits(['submit', 'cancel'])
let { okText, cancelText, visible, title, width, destroyOnClose, footer } = defineProps({
  okText: {
    type: String,
    default: '确 定'
  },
  // 取消按钮的文字
  cancelText: {
    type: String,
    default: '取 消'
  },
  visible: {
    type: Boolean,
    default: false
  },
  // 弹窗标题
  title: {
    type: String,
    default: "标题"
  },
  footer: {
    type: String,
    default: "确定取消按钮"
  },
  // 弹窗宽度
  width: {
    type: Number,
    default: 520
  },
  // 弹窗关闭时是否销毁里面的子元素
  destroyOnClose: {
    type: Boolean,
    default: false
  }
})

const submit = () => {
  emit('submit')
}
const cancel = () => {
  emit('cancel')
}

</script>

<style lang="scss" scoped>
</style>