import axios from 'axios'
import basicUrl from './basicUrl'
import router from '@/router'
import { message } from 'ant-design-vue';
import getMd5Form from '@/utils/getMd5Form.js'
// import { useStore } from 'vuex'
// let store = useStore()
import store from '../store';

export const instance = axios.create({
  baseURL: basicUrl,
  timeout: 60000
});

// 设置放行路由，放行错误控制
var role = ['/api/v1/my/print_pdf']
function addInterceptors (face) {
  face.interceptors.request.use(config => {
    store.commit('loading/add')
    if (config.url != '/api/v1/auth/refresh_token' || config.url != '/api/v2/login') {
      // await getTime()
    }

    if (window.localStorage.getItem('userInfo')) {
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
      config.headers['Authorization'] = `Bearer ${userInfo.token}`
    }

    if (!config.headers.isRefresh) {
      delete config.headers.isRefresh
      if (config.method == 'get') {
        if (!config.params) {
          config.params = {}
        }
        config.params.sign = getMd5Form(config.params)
      }
      else {
        if (!config.data) {
          config.data = {}
        }
        config.data.sign = getMd5Form(config.data)
      }
      delete config.headers.isRefresh
    }
    return config
  })
  face.interceptors.response.use(async config => {
    store.commit('loading/sub')

    if (role.findIndex(item => item == config.config.url) != -1) {
      return config
    }
    config = config.data
    if (config.status === undefined && config.msg === undefined) {
      return config
    }
    return await new Promise((resolve, reject) => {
      if (config.status == 200) {
        resolve(config)
      } else {
        // 当状态码不为200的时候，直接拦截
        if (config.msg == 'error') {
          message.error(config.data.msg)
        } else {
          message.error(config.msg)
        }
        // console.log(config.status)
        if (config.status == 410000) {
          window.sessionStorage.clear()
          router.push('/login?not_auto_login=1')
        }
        reject(config)
      }
    })
  }, error => {
    if (error.message.includes("timeout")) {
      message.error("请求超时，请稍后重试")
      if (store.state.loading.spinning > 0) {
        store.commit('loading/sub')
      }
    }
    console.log(error, "error")
  })
}

addInterceptors(instance)
