const state = {
  // 控制是否显示loading
  subjectList: [],
}

const mutations = {
  setSubjectList (state, list) {
    state.subjectList = list
  }
}


export default {
  state, mutations, namespaced: true
}