const state = {
  // 控制是否显示loading
  spinning: 0,
}

const mutations = {
  showLoading (state, status) {
    state.spinning = status
  },
  add (state) {
    state.spinning += 1
  },
  sub (state) {
    state.spinning -= 1
  },
  clearSpinning (state) {
    state.spinning = 0
  }
}


export default {
  state, mutations, namespaced: true
}