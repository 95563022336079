<template>
  <div class="loading_wrap"
       v-if="spinning > 0">
    <a-spin :spinning="spinning > 0"
            tip="加载中"
            size="large"
            :delay="delayTime">
      <div class="spin-content">
      </div>
    </a-spin>
  </div>
</template>

<script setup>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { computed } from '@vue/runtime-core'

let delayTime = ref(300)
let store = useStore();
let spinning = computed(() => store.state.loading.spinning)
</script>

<style lang="scss" scoped>
.loading_wrap {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 1999;
  transition: 0.5 all;
}
.spin-content {
  border: 1px solid #91d5ff;
  width: 100%;
  height: 100vh;
}
::v-deep .ant-spin-nested-loading > div > .ant-spin {
  max-height: 100vh;
}
::v-deep .ant-spin-dot-item {
  width: 14px;
  height: 14px;
  margin: 20px;
}
::v-deep .ant-spin-dot {
  font-size: 28px;
}
</style>

