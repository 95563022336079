import { createStore } from 'vuex'
import loading from './modules/loading.js'
import subject from './modules/subject'

const store = createStore({
  state: {
    userInfo: {
      avatar: ''
    },
    activeKeep: false,
    // 被缓存的路由
    keepRouteList: []
  },
  mutations: {
    SET_USERINFO (state, payload) {
      state.userInfo = payload
    },
    SET_KEEP_STATUS (state, payload) {
      state.activeKeep = payload
    },
    SET_ROUTE_LIST (state, payload) {
      state.keepRouteList.push(payload)
    },
    CLEAR_ROUTE_CACHE (state) {
      state.keepRouteList = []
    }
  },
  actions: {},
  modules: {
    loading,
    subject
  }
})


export default store

